<template>
  <div class="mod-org" ref="mod-org">
    <div class="container-left" :style="{ height: clientHeight + 'px' }">
      <el-input placeholder="输入关键字进行过滤" size="small" v-model="filterText" />
      <div class="container-left-tree">
        <el-tree
          ref="tree"
          :data="treeData"
          :props="defaultProps"
          node-key="id"
          highlight-current
          @node-click="treeNodeClick"
          :expand-on-click-node="false"
          :default-expanded-keys="defaultExpanded"
          :filter-node-method="filterNode"
        ></el-tree>
      </div>
    </div>
    <div class="container-right">
      <div class="buttonOut">
        <el-button type="primary" size="small" @click="add">添加</el-button>
      </div>
      <div style="width: 100%; padding: 8px">
        <el-table :data="tableData" border>
          <el-table-column header-align="center" align="center" label="序号" type="index" width="60"> </el-table-column>
          <el-table-column prop="dataName" header-align="center" align="center" label="字典名称"> </el-table-column>
          <el-table-column prop="dataCode" header-align="center" align="center" label="字典编码"> </el-table-column>

          <el-table-column prop="dataValue" header-align="center" align="center" label="字典值"> </el-table-column>

          <el-table-column prop="username" header-align="center" align="center" label="状态">
            <template slot-scope="scope">
              <span>{{ scope.row.dataState ? "禁用" : "启用" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dataDesc" header-align="center" align="center" label="字典描述">
            <template slot-scope="scope">
              <span class="ellipsis" :title="scope.row.dataDesc">{{ scope.row.dataDesc }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" width="300" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)">修改</el-button>
              <el-button type="text" style="color: #f56c6c" @click="del(scope.row)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="show" :title="title" :close-on-click-modal="false" width="500px">
      <el-form :model="formData" :rules="rules" ref="dataForm" label-width="80px">
        <el-form-item label="所属上级" style="width: 400px">
          <el-input v-model="parentName" disabled></el-input>
        </el-form-item>
        <el-form-item label="字典名称" prop="dataName" style="width: 400px">
          <el-input v-model="formData.dataName"></el-input>
        </el-form-item>
        <el-form-item label="字典编码" prop="dataCode" style="width: 400px">
          <el-input v-model="formData.dataCode" :disabled="title == '修改'"></el-input>
        </el-form-item>
        <el-form-item label="字典值" prop="dataValue" style="width: 400px">
          <el-input v-model="formData.dataValue"></el-input>
        </el-form-item>
        <el-form-item label="字典排序" prop="dataSort" style="width: 400px">
          <el-input v-model="formData.dataSort"></el-input>
        </el-form-item>
        <el-form-item label="字典描述" style="width: 400px">
          <el-input v-model="formData.dataDesc" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
        </el-form-item>
        <el-form-item label="字典状态">
          <el-switch
            size="large"
            v-model="formData.dataState"
            :active-value="0"
            :inactive-value="1"
            active-text="启用"
            inactive-text="禁用"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="show = false">取消</el-button>
        <el-button type="primary" size="small" @click="submit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { treeDataTranslate, type } from "@/utils";
export default {
  name: "dictionaries",
  data() {
    return {
      curNode: {},
      treeData: [],
      defaultProps: {
        children: "children",
        label: "dataName",
      },
      filterText: "",
      tableData: [{}],
      title: "添加",
      show: false,
      formData: {
        dataState: 0,
      },
      rules: {
        dataName: {
          required: true,
          message: "字典名称不能为空",
          trigger: "blur",
        },
        dataCode: {
          required: true,
          message: "字典编码不能为空",
          trigger: "blur",
        },
        dataValue: {
          required: true,
          message: "字典值不能为空",
          trigger: "blur",
        },
        dataSort: {
          required: true,
          message: "字典排序不能为空",
          trigger: "blur",
        },
      },
      pageNo: 1,
      pageSize: 30,
      totalPage: 0,
    };
  },
  computed: {
    clientHeight() {
      return document.documentElement["clientHeight"] - 122;
    },
    defaultExpanded() {
      let _B = Boolean(this.treeData && this.treeData.length);
      let arr = _B ? [this.treeData[0].id] : [];
      return arr;
    },
    parentName() {
      // 处理所属上级
      if (this.title == "添加") {
        return this.curNode.dataName;
      } else if (this.title == "修改") {
        if (type(this.curRowPID) == "null") {
          return "最外层节点";
        } else {
          console.log(this.dicData.filter((i) => i.id === this.curRowPID));
          return this.dicData.filter((i) => i.id === this.curRowPID)[0].dataName;
        }
      }
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    this.getTreeList();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.nodeName.indexOf(value) !== -1;
    },
    treeNodeClick(node) {
      this.curNode = JSON.parse(JSON.stringify(node));
      this.currentChangeHandle(1);
    },
    //重置添加表单
    // 表单重置
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
    //添加数据字典
    add() {
      this.resetForm("dataForm");
      this.title = "添加";
      this.show = true;
    },
    //修改数据字典
    edit(item) {
      this.formData = item;
      this.curRowPID = item.pid;
      this.title = "修改";
      this.show = true;
    },
    //删除数据字典
    del(item) {
      this.$confirm(`确定对[用户:${item.dataName}]进行[删除]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (!this.isAuth("sys:dictionary:delete")) return this.$message.warning("没有权限!sys:dictionary:delete");
          this.$http({
            url: this.$http.adornUrl(`sys/dictionary/delete`),
            method: "delete",
            params: {
              id: item.id,
            },
          })
            .then((res) => {
              this.getTreeList();
              this.$message.success(res.data.msg);
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        })
        .catch((err) => {});
    },
    //查询字典树
    getTreeList() {
      this.$http({
        url: this.$http.adornUrl("sys/dictionary/dictionaryTree"),
        method: "get",
      })
        .then((res) => {
          this.dicData = res.data.data;
          this.treeData = treeDataTranslate(res.data.data, "id", "pid");
          this.curNode = this.treeData && this.treeData.length ? this.treeData[0] : {};
          this.$refs.tree.setCurrentKey(this.curNode.id);
          this.currentChangeHandle(1);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    getDictionariesList() {
      let { id } = this.curNode;
      if (!this.isAuth("sys:dictionary:dictionaryPage"))
        return this.$message.warning("没有权限!sys:dictionary:dictionaryPage");
      this.$http({
        url: this.$http.adornUrl("sys/dictionary/dictionaryPage"),
        method: "get",
        // data: this.$http.adornData(),
        params: {
          pId: id,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        },
      })
        .then((res) => {
          this.totalPage = Number(res.data.data.total);
          this.tableData = res.data.data.records;
        })
        .catch((err) => {});
    },
    //分页条数切换
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getDictionariesList();
    },
    //分页页数切换
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDictionariesList();
    },
    submit() {
      this.$refs.dataForm.validate((valid) => {
        if (!valid) return;
        if (this.title == "添加") {
          this.formData.pid = this.curNode.id;
          if (!this.isAuth("sys:dictionary:add")) return this.$message.warning("没有权限!sys:dictionary:add");
          this.$http({
            url: this.$http.adornUrl("sys/dictionary/add"),
            method: "post",
            data: this.formData,
          })
            .then((res) => {
              this.show = false;
              this.resetForm("dataForm");
              // this.getDictionariesList();
              this.getTreeList();
              this.$message.success(res.data.msg);
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        } else if ((this.title = "修改")) {
          if (!this.isAuth("sys:dictionary:update")) return this.$message.warning("没有权限!sys:dictionary:update");
          this.$http({
            url: this.$http.adornUrl("sys/dictionary/update"),
            method: "put",
            data: this.formData,
          })
            .then((res) => {
              this.resetForm("dataForm");
              this.show = false;
              // this.getDictionariesList();
              this.getTreeList();
              this.$message.success(res.data.msg);
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        }
      });
    },
  },
};
</script>